class Covidence.Account extends Covidence.RelationalModel
  relations: [{
    type: Backbone.HasOne
    key: "current_plan"
    relatedModel: "Covidence.Plan"
    collectionType: "Covidence.Plans"
  }]

  canPurchasePlan: (plan) ->
    !@hasReviewsRemaining() ||
      plan.get('custom') == true ||
      !@get('current_plan')? ||
      plan.get('price') > @get('current_plan').get('price')

  canCreateReview: (review) ->
    if review.get('external_reference') != undefined
      true
    else
      @get('available_review_count') > 0

  hasReviewsRemaining: ->
    remaining = @get('available_review_count')
    remaining = Infinity unless remaining? # nil ~ Infinity because JSON doesn't have Infinity
    remaining > 0

  planUpgradeMotivation: ->
    if @get('current_plan')?.get('introductory')
      "Thanks for helping us shape Covidence in the early years. You can continue " +
        "to work on your existing reviews, but if you need to create new reviews, " +
        "you will need to purchase a plan."
    else if @get('current_plan')? && !@hasReviewsRemaining()
      "You have no more reviews left on your current plan, either due to expiry " +
        "of your subscription or due to exhausting your review allowance. Choose " +
        "a new plan to create further reviews."

