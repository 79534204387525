class Covidence.Note extends Covidence.RelationalModel
  urlRoot: '/api/v1/notes'

  dateCreated: ->
    @get('created_at')

  validate: (attrs) ->
    return {note: ["Note is required"]} unless attrs.note

class Covidence.Notes extends Backbone.Collection
  model: Covidence.Note
  url: '/api/v1/notes'
  comparator: 'created_at'
