class Covidence.PaymentService extends Covidence.RelationalModel

  # createPayment: (creditCard) =>
  #   @pinApi = new Pin.Api(@get('api_key'), @get('environment'))
  #   @pinApi.createCardToken(creditCard.attributes).then(@handleSuccess, @handleError).done()
  #   @

  # handleSuccess: (card) =>
  #   @model.trigger('success')
  #   # @$('.pay').prop('disabled', false)
  #   # @close()
  #   @

  # handleError: (response) =>
  #   errors = _(response.messages).map (message) =>
  #     error = {}
  #     error[message.param] = message.message
  #     error
  #   @model.trigger("invalid", @model, errors, {})
  #   @
