class Covidence.PlanPaymentSuccessView extends Backbone.View

  className: 'payment-success'

  events:
    "click .continue": "continue"

  initialize: (options) ->
    @payment = @model.get('payment')
    @selectedPlan = @model.get('selected_plan')
    @template = HandlebarsTemplates['plans/payment_success']

  render: ->
    numberOfReviews = Covidence.Utils.pluralize(@selectedPlan.get('number_of_reviews'), 'review')
    html = @template({planName: @selectedPlan.get('name'), numberOfReviews: numberOfReviews})
    @$el.html(html)
    @

  continue: ->
    @model.trigger('complete', @selectedPlan.get('name'))