class Covidence.AuditEvent extends Covidence.RelationalModel

  dateCreated: ->
    @get('created_at')

  displayClass: ->
    @get('name').replace(/^(covidence|regroup)\b/, 'event').replace(/\./g, '-')

class Covidence.AuditEvents extends Backbone.Collection
  model: Covidence.AuditEvent

  comparator: 'created_at'
