class BackboneModelError extends Error
  name: 'BackboneModelError'

class Covidence.RelationalModel extends Backbone.RelationalModel

  initialize: (options) ->
    super
    @listenTo(@, "error", @handleError)
    @listenTo(@, 'request', @handleRequest)
    @listenTo(@, 'sync', @handleSync)
    @listenTo(@, 'change:url', @setUrl)

  handleError: (model, response, options) ->
    if [502, 503, 504].lastIndexOf(response.status) >= 0
      Covidence.trigger('error', model, response, options)
    else if [422, 409].lastIndexOf(response.status) >= 0 && response.responseJSON.errors != undefined
      @trigger("invalid", @, response.responseJSON.errors, options)
      if model.collection
        model.collection.trigger('invalid', model.collection, response.responseJSON['errors'], options);
      Covidence.trigger('error', model, response, options)
    else
      MetaError.notify(new BackboneModelError(response.responseText), {
        context: {
          model:
            name: model.constructor.name || model.constructor.toString()
            url: model.url?()
            data: model.toJSON()
          }
        }
      )


  handleRequest: (model, request, options) ->
    Covidence.trigger('saving', model, request, options)
    @

  handleSync: (model, response, options) ->
    Covidence.trigger('saved', model, response, options)
    @

  setUrl: (model, url, options) ->
    @url = @get('url')
    @unset('url', {silent: true})
    @
