class Covidence.PlansPurchaseRouter extends Backbone.Router

  initialize: (options) ->
    plans = options.plans
    @completeUrl = options.completeUrl
    @fromSignup = options.fromSignup
    view = new Covidence.PlanPurchaseWizard({el: '#wizard', plans: plans, account: Covidence.account})
    @listenTo(view, 'complete', @complete)
    view.start()

  complete: (planName) ->
    if @fromSignup
      window.location.replace @completeUrl + "?ref=signup_" + planName.toLowerCase()
    else
      window.location.replace @completeUrl
