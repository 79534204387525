class Covidence.UserPreferences
  _.extend(@, Backbone.Events)
  ds = null

  class PreferencesDatastore
    @preferences:
      keywordHighlightingEnabled: "keywordHighlightingEnabled"

    storageNamespace: "covidence.userPreferences"

    set: (key, value) ->
      localStorage[@keyify(key)] = value
      Covidence.UserPreferences.trigger("change")

    get: (key) ->
      localStorage.getItem(@keyify(key))

    keyify: (partialKey) ->
      "#{@storageNamespace}.#{partialKey}"

  @datastore: ->
    ds ?= new PreferencesDatastore()

  @isKeywordHighlightingEnabled: ->
    @datastore().get(PreferencesDatastore.preferences.keywordHighlightingEnabled) == "1"

  @toggleKeywordHighlighting: ->
    if @isKeywordHighlightingEnabled() then @disableKeywordHighlighting() else @enableKeywordHighlighting()

  @enableKeywordHighlighting: ->
    @datastore().set(PreferencesDatastore.preferences.keywordHighlightingEnabled, "1")

  @disableKeywordHighlighting: ->
    @datastore().set(PreferencesDatastore.preferences.keywordHighlightingEnabled, "0")
