class Covidence.PlanPaymentFailureView extends Backbone.View

  className: 'payment-failure'

  events:
    "click .start-again": "start_again"
    "click .return-home": "return_home"

  initialize: (options) ->
    @payment = @model.get('payment')
    @template = HandlebarsTemplates['plans/payment_failure']

  render: ->
    isFatal = @payment.get('fatal_errors') != null
    message = if isFatal then "This could be for a number of reasons, sorry we can’t be more specific. We haven’t been notified of a successful/unsuccessful payment. We recommend you contact your financial institution before trying again." else @payment.get('reportable_errors')
    @$el.html(@template({message: message, isFatal: isFatal}))
    @

  return_home: ->
    @model.trigger('abort')

  start_again: ->
    @model.trigger('start')
