class Covidence.DismissableMessageView extends Backbone.View

  className: 'alert'

  events:
    "click .close": 'close'

  initialize: (options) ->
    @template = HandlebarsTemplates['message']
    @message = options.message
    @status = options.status || 'success'

  render: ->
    @$el.html(@template(message: @message))
    @$el.addClass("alert-#{@status}")
    @

  close: ->
    @remove()