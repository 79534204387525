class Covidence.PlanPurchaseWizard extends Backbone.View

  initialize: (options) ->
    @completeUrl = options.completeUrl
    @title = options.title || ''
    @model = new Backbone.Model({plans: options.plans, account: options.account})
    @listenTo(@model, 'change:selected_plan', @updateTitleToPlan)
    @listenTo(@model, 'choosePlan', @showPlanPaymentView)
    @listenTo(@model, 'submitSuccess', @submitSuccess)
    @listenTo(@model, 'cancelPayment', @cancelPayment)
    @listenTo(@model, 'paymentSuccess', @paymentSuccess)
    @listenTo(@model, 'paymentFailure', @paymentFailure)
    @listenTo(@model, 'complete', @complete)
    @listenTo(@model, 'start', @start)
    @listenTo(@model, 'abort', @abort)
    @currentView = new Covidence.PlansView({model: @model, title: @title})

  render: ->
    @renderView(@currentView)
    @

  showPlanPaymentView: ->
    @renderView(new Covidence.PlanPaymentView({model: @model, promotions: Covidence.promotions}))
    @

  submitSuccess: ->
    @renderView(new Covidence.PlanPaymentStatusView({model: @model}))
    @

  cancelPayment: ->
    @renderView(new Covidence.PlansView({model: @model}))
    @

  paymentSuccess: ->
    @model.get('account').fetch()
    @renderView(new Covidence.PlanPaymentSuccessView({model: @model}))
    @

  paymentFailure: ->
    @renderView(new Covidence.PlanPaymentFailureView({model: @model}))
    @

  complete: (planName) ->
    @trigger('complete', planName)

  abort: ->
    window.location.href = "/"

  start: ->
    if (selected_plan = @model.get('plans').get(Covidence.selectedPlanId || -1))
      @model.set(selected_plan: selected_plan)
      @model.trigger('choosePlan')
    else
      @render()

  setHeading: (heading) ->
    $('.navigation__secondary__top__title h2').text(heading)

  updateTitleToPlan: (_model, selected_plan) ->
    if selected_plan
      planName = selected_plan.get('name')
      @setHeading("Buy a #{planName} plan")
    else
      @setHeading("Buy a plan")

  renderView: (view) ->
    if @currentView
      @currentView.remove()
    @currentView = view
    @$el.html(@currentView.render().el)
    @
