class Covidence.Wizard extends Backbone.View

  initialize: (options) ->
    @steps = options.steps || new Backbone.Collection()
    @currentStep = 0

  render: ->
    @currentView = @getCurrentView()
    @listenToOnce(@currentView, 'complete', @nextView)
    @listenToOnce(@currentView, 'cancel', @previousView)
    @$el.html(@currentView.render().el)
    @

  nextView: ->
    @currentView.remove()
    @currentStep += 1
    @render()
    @

  previousView: ->
    @currentView.remove()
    @currentStep -= 1
    @render()
    @

  getCurrentView: ->
    view = @steps.at(@currentStep).get('view')
    new view({model: @state})