class Covidence.PlansView extends Backbone.View
  className: 'choose-a-plan'

  initialize: (options) ->
    @template = HandlebarsTemplates['plans/plans']
    @title = options.title

  render: =>
    account = @model.get('account')
    @$el.html(@template(title: @title, planUpgradeMotivation: account.planUpgradeMotivation()))
    plans = @model.get('plans')
    columnWidth = Math.floor(75/plans.length)

    plans.each (plan) =>
      view = new Covidence.PlanView({model: plan, state: @model, columnWidth: columnWidth})
      @listenToOnce(view, 'complete', => @trigger('complete'))
      @$el.find('.plans .costing').append(view.render().el)

      valuePropositionView = new Covidence.PlanValuePropositionView({model: plan, columnWidth: columnWidth})
      @$el.find('.plans .who-is-it-for').append(valuePropositionView.render().el)
      @$el.find('.plans .number-of-project-owners').append('<td class="plan-cell">1</td>')
      numberOfReviewsView = new Covidence.PlanNumberOfReviewsView({model: plan, columnWidth: columnWidth})
      @$el.find('.plans .number-of-reviews').append(numberOfReviewsView.render().el)
      numberOfReviewersView = new Covidence.PlanNumberOfReviewersView({model: plan, columnWidth: columnWidth})
      @$el.find('.plans .collaborators-on-reviews').append(numberOfReviewersView.render().el)
      planExpiryView = new Covidence.PlanExpiryView({model: plan, columnWidth: columnWidth})
      @$el.find('.plans .expiry').append(planExpiryView.render().el)
    @$el.append(new Covidence.PlanInclusionsView({collection: Covidence.planInclusions}).render().el)
    @


class Covidence.PlanCellView extends Backbone.View

  className: 'plan-cell'

  tagName: 'td'

  initialize: (options) ->
    @columnWidth = options.columnWidth

  render: ->
    @$el.html(@content())
    @$el.css("width", "#{@columnWidth}%")
    if @model.get('custom') == true
      @$el.addClass('custom')
    @postRender()
    @

  content: ->
    ''

  postRender: ->

class Covidence.PlanView extends Covidence.PlanCellView

  className: 'plan'

  events:
    "click .choose-plan": "choosePlan"

  initialize: (options) ->
    super(options)
    @state = options.state
    @account = @state.get('account')
    @template = HandlebarsTemplates['plans/plan']

  content: ->
    price = accounting.formatMoney(@model.get('price'), {precision: 0})
    @template({name: @model.get('name'), price: price})

  postRender: ->
    unless @account.canPurchasePlan(@model)
      @$el.find('.choose-plan').prop('disabled', 'disabled')
    if @account.get('current_plan') == @model
      @$el.find('.choose-plan').html('Current Plan')
    @

  choosePlan: ->
    @state.set({selected_plan: @model})
    Covidence.selectedPlanId = @model.id
    @state.trigger('choosePlan')
    @

class Covidence.PlanValuePropositionView extends Covidence.PlanCellView

  content: ->
    @model.get('value_proposition')

class Covidence.PlanNumberOfReviewsView extends Covidence.PlanCellView

  initialize: (options) ->
    @template = HandlebarsTemplates['plans/number_of_reviews']

  content: ->
    limit = @model.get('number_of_reviews') || 'Unlimited'
    renewable = @model.get('renewable') == true
    @template({numberOfReviews: limit, renewable: renewable})


class Covidence.PlanNumberOfReviewersView extends Covidence.PlanCellView

  content: ->
    @model.get('number_of_reviewers') || 'Unlimited'

class Covidence.PlanExpiryView extends Covidence.PlanCellView

  content: ->
    '12 months <br /> (from payment date)'

class Covidence.InclusionView extends Backbone.View

  className: 'inclusion row'

  initialize: (options) ->
    @template = HandlebarsTemplates['plans/inclusion']

  render: ->
    @$el.html(@template({title: @model.title, content: @model.content}))
    @
