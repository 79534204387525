class Covidence.PlanPaymentView extends Backbone.View
  className: "plan-payment"

  events:
    "click .pay": "purchasePlan"
    "click .cancel": "cancelPurchase"

  bindings:
    '#address_line1' : 'address_line1'
    '#address_city'  : 'address_city'
    '#address_country' :
      observe : 'address_country'
      selectOptions:
        collection: 'this.countries'
        labelPath: 'name'
        valuePath: 'code'
        defaultOption:
          label: ""
          value: null
    '#number' : 'number'
    '#name' : 'name'
    '#expiry_month' :
      observe: 'expiry_month'
      selectOptions:
        collection: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
        defaultOption:
          label: ""
          value: null
    '#expiry_year' :
      observe: 'expiry_year'
      selectOptions:
        collection: 'this.expiryYears'
        defaultOption:
          label: ""
          value: null
    '#cvc' : 'cvc'

  initialize: (options) ->
    @reviewer = Covidence.reviewer
    @promotions = options.promotions
    @countries = Covidence.countries
    currentYear = new Date().getFullYear()
    @expiryYears = _(12).times (index) ->
      currentYear + index
    @creditCard = new Covidence.CreditCard({name: @reviewer.fullName(), expiry_year: currentYear, expiry_month: new Date().getMonth() + 1})
    @template = HandlebarsTemplates['plans/payment']
    @model.unset('discounted_price', {silent: true})
    @listenTo(@model, 'change:discounted_price', @applyDiscountedPrice)
    @

  render: ->
    selectedPlan = @model.get('selected_plan')
    options =
      planName: selectedPlan.get('name')
      planPrice: accounting.formatMoney(selectedPlan.get('price'), {precision: 0})
      numberOfReviews: Covidence.Utils.pluralize(selectedPlan.get('number_of_reviews'), 'review')
    @$el.html(@template(options))
    promotionView = new Covidence.PromotionView(collection: @promotions, model: @model).render()
    @$('.header').append(promotionView.el)
    @stickit(@creditCard)
    @

  applyDiscountedPrice: (model, discountedPrice) ->
    precision = if discountedPrice % 1 != 0 then 2 else 0
    @$('.plan-price').html(accounting.formatMoney(discountedPrice, {precision: precision}))
    @$('.original-price').html(accounting.formatMoney(model.get('selected_plan').get('price'), {precision: 0}))
    @

  purchasePlan: =>
    @clearErrorMessages()
    @paymentService = Covidence.paymentService
    @pinApi = new Pin.Api(@paymentService.get('public_api_key'), @paymentService.get('environment'))
    @pinApi.createCardToken(@creditCard.attributes).then(@handleSuccess, @handleError).done()
    @$('.pay').prop('disabled', true)
    @

  cancelPurchase: ->
    @model.set({selected_plan: null})
    @model.trigger('cancelPayment')
    @

  handleSuccess: (card) =>
    promotionId = if @model.get('promotion') then @model.get('promotion').get('id') else null
    payment = new Covidence.Payment(card_token: card.token, plan_id: @model.get('selected_plan').get('id'), promotion_id: promotionId)
    @model.set({payment: payment})
    @listenToOnce(payment, 'sync', => @model.trigger('submitSuccess'))
    payment.save()
    @

  handleError: (response) =>
    @$('.pay').prop('disabled', false)
    _(response.messages).each (message) =>
      @$(".form-group.#{message.param}").addClass("has-error")
      @$(".#{message.param} .error").html(message.message)
    @

  clearErrorMessages: ->
    @$('.form-group').removeClass("has-error")
    @$('.error').html('')
    @
