class Covidence.CreditCard extends Backbone.Model

  defaults:
    number:           ""
    name:             ""
    expiry_month:     ""
    expiry_year:      ""
    cvc:              ""
    address_line1:    ""
    address_city:     ""
    address_country:  ""