Covidence.BrowserNotificationController =
  initialize: ->
    @el = $("#browser-notification-view")
    @el.find("a.ss-delete").on("click", => @hide())

    @show()

  hide: ->
    @el.hide()
    $.cookie('browser-notification', 'seen', { expires: 30 })

  show: ->
    @el.show() if @notify()

  notify: ->
    return Covidence.unsupportedBrowser && !$.cookie('browser-notification')
