class Covidence.ReviewStatistics extends Backbone.Model
  remote: true

  detail: (category, reviewerId)->
    detailedStats = new Covidence.ReviewDetailedStatistics()
    detailedStats.baseUrl = @url
    detailedStats.category = category
    detailedStats

class Covidence.ReviewDetailedStatistics extends Backbone.Model
  remote: true

  url: ->
    @baseUrl + '/detailed?category=' + @category

  percentageFor: (attribute) ->
    @calculatePercentage(@get(attribute)) * 100

  totalProgress: ->
    @get('complete') + @get('partially_complete') + @get('consensus_required') + @get('not_started')

  calculatePercentage: (value) ->
    if @totalProgress() == 0
      return 0
    else
      return value / @totalProgress()

  isComplete: ->
    (@get('consensus_required') + @get('available')) == 0

  notStarted: ->
    @totalProgress() == 0
