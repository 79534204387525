import { sendFullTextEvent, UPLOAD_METHODS } from '../../../util/pendoTrack';

class Covidence.S3DirectUploadView extends Backbone.View
  className: "s3-direct-upload-view"
  progressBarTarget: ".uploads-container"

  template: ->
    """
    <div class="upload-container">
      <input type="file" name="file" class="direct-file-upload" multiple="multiple"/>
    </div>

    <ul class="uploads-container"></ul>

    <script id="template-upload" type="text/html">
      <li id="upload_{{file.unique_id}}">
        <div class="progress progress-striped active">
          <h6>{{file.name}}</h6>
          <div class="bar" style="width: 0%"/>
          <a href='javascript:void(0)' class='result'></a>
        </div>
        <div class='error'></div>
      </li>
    </script>
    """
  events:
    "click a.result.error": "removeContext"

  initialize: (options) ->
    @settings = _.extend({allow_multiple_files: true}, options)
    @filesToProcess = 0
    @filesUploaded = 0
    # Assigning it to self so that it can be mocked
    @pendoTrack = sendFullTextEvent

  render: ->
    @$el.empty()
    @$el.append(@template())
    $.get("/documents/storage_config.json", (data) =>
      _.extend(@settings, data)
      @setupJQueryFileUpload()
    ).fail((xhr, status, error)->
    )
    return this

  removeContext: (e) ->
    $(e.currentTarget).parents("li").remove();

  onUploadAdd: (e, uploader) =>
    @filesToProcess += 1
    @filesUploaded += 1
    file = uploader.files[0]
    file.unique_id = Math.random().toString(36).substr(2,16)

    if $('#template-upload').length > 0
      uploader.context = $($.trim($.mustache($("#template-upload").html(), file: file)))
      $(uploader.context).appendTo(@$(@progressBarTarget))

    uploader.submit()

  onUploadDone: (e, uploader) =>
    @filesToProcess -= 1
    file = uploader.files[0]
    doc = new Covidence.Document(file: uploader.files[0], attachable_id: @settings.attachableId, attachable_type: @settings.attachableType)
    doc.url = @settings.callback_url
    @listenTo doc, 'sync', =>
      uploader.context.find('.result').addClass('ss-check').addClass('success')
    @listenTo doc, 'invalid', (model, errors, options) =>
      errorText = ""
      for field, error of errors
        errorText = "#{errorText}\n#{field} - #{error}"
      uploader.context.find('.error').text(errorText)
      uploader.context.find('.result').addClass('ss-delete').addClass('error')
    doc.save()
    if @filesToProcess < 1
      @pendoTrack({
        uploadMethod: UPLOAD_METHODS.LEGACY_SINGLE_STUDY_MODAL,
        fileCount: @filesUploaded,
      })
      @filesUploaded = 0
    @

  setupJQueryFileUpload: ->
    $uploadEl = @$(".upload-container")
    settings = @settings
    $uploadEl.fileupload
      url: @settings.url
      type: 'POST'
      formData: ->
        data = []
        settings.formData["key"] = settings.formData["key"].replace(":attachable_id", settings.attachableId)
        settings.formData["key"] = settings.formData["key"].replace(":attachable_type", settings.attachableType)
        $.each(settings.formData, (name, value) ->
          data.push
            name: name,
            value: value
        )
        fileType = ""
        if "type" of @files[0]
          fileType = @files[0].type
        data.push
          name: "Content-Type"
          value: fileType
        data

      add: @onUploadAdd

      progress: (e, uploader) ->
        if uploader.context
          progress = parseInt(uploader.loaded / uploader.total * 100, 10)
          uploader.context.find('.bar').css('width', progress + '%')

      done: @onUploadDone

      fail: (e, uploader) =>
        content = @buildData uploader
        content.error_thrown = uploader.errorThrown
        uploader.context.find('.result').addClass('ss-delete').addClass('error') if uploader.context

    $uploadEl.bind('fileuploadstart', => @trigger('fileuploadstart', @))
    $uploadEl.bind('fileuploaddone', => @trigger('fileuploaddone', @))
