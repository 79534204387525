class Covidence.PlanPaymentStatusView extends Backbone.View

  className: 'payment-status'

  initialize: (options) ->
    @payment = @model.get('payment')
    @listenTo(@payment, 'sync', @refresh)
    @template = HandlebarsTemplates['plans/payment_status']

  render: ->
    @$el.html(@template())
    @pollPayment()
    @

  refresh: ->
    if @payment.isComplete() == true
      clearInterval(@poll)
      @model.trigger('paymentSuccess')
    else if @payment.isFailure() == true
      clearInterval(@poll)
      @model.trigger('paymentFailure')
    @

  pollPayment: ->
    @poll = setInterval(=>
      if $.contains(document, @$el[0])
        @payment.fetch()
      else
        clearInterval(@poll)
    , 5000)
    @
