class Covidence.PromotionView extends Backbone.View

  className: 'promotion text-left'

  events:
    "click .apply-promotion": "applyPromotion"

  initialize: (options) ->
    @template = HandlebarsTemplates['plans/promotion']
    @listenTo(@collection, 'sync', @toggleVisibility)
    @collection.fetch()

  render: ->
    @$el.html(@template())
    @toggleVisibility()
    @

  toggleVisibility: ->
    if @collection.isEmpty()
      @hide()
    else
      @show()
    @

  show:  ->
    @$el.show()
    @

  hide: ->
    @$el.hide()
    @

  applyPromotion: ->
    promoCode = @$('.promo_code').val().trim()
    promotion = @collection.find (model) ->
      model.get('code').toLowerCase() == promoCode.toLowerCase()
    @$('.promo-message').show()
    if promotion
      discount = promotion.get('percentage_discount') * 100
      @$('.promo-message').html("Awesome! A #{discount}% discount has been successfully applied.")
      @$('.promo-message').removeClass('alert-danger')
      @$('.promo-message').addClass('alert-success')
      @calculateDiscountedPrice(promotion)
    else
      @$('.promo-message').html('The promo code you entered is invalid.')
      @$('.promo-message').removeClass('alert-success')
      @$('.promo-message').addClass('alert-danger')
    @

  calculateDiscountedPrice: (promotion)->
    selectedPlan = @model.get('selected_plan')
    discountedPrice = Math.round(100 * selectedPlan.get('price') * (1 - promotion.get('percentage_discount'))) / 100
    @model.set(discounted_price: discountedPrice, promotion: promotion)
    @
