class Covidence.Reviewer extends Covidence.RelationalModel
  fullName: ->
    @get("first_name") + " " + @get("last_name")

  allowPermission: (review, permission) ->
    !@denyPermission(review, permission)

  denyPermission: (review, permission) ->
    permissionGroup = review.get("permissions").findWhere({id: permission})
    if permissionGroup
      allowed = permissionGroup.get("member_ids")
      return allowed.length > 0 && !_.contains(allowed, @get("id"))
    else
      return false

  showRoBInstruction: ->
    !!@get("show_rob_instruction")

  @calculatedProperties: ["fullName"]

class Covidence.Reviewers extends Backbone.Collection
  model: Covidence.Reviewer


