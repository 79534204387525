class Covidence.Payment extends Covidence.RelationalModel
  urlRoot: '/payments'

  isComplete: ->
    @get('status') == 'complete'

  isFailure: ->
    @get('status') == 'failed'

  finishedProcessing: ->
    @get('status') != null