Covidence.UserNotificationView =
  initialize: ->
    @el = $("#user-notification-view")
    @el.find("a").on("click", => @hide())
    @show()

  hide: ->
    @el.hide()
    $.cookie('user-notification', 'seen', { expires: 1 })

  show: ->
    @el.show() if !$.cookie('user-notification')

