window.Covidence ||= {}
_.extend(window.Covidence, Backbone.Events)

Covidence.Application =
  initialize: (feedback_attrs) ->
    @feedbackView = new Covidence.FeedbackView(feedback_attrs)
    @feedbackView.render()

    @setupErrorHooks()
    Covidence.on('unauthorized', @redirectToLogin)

  setupErrorHooks: ->
    $(window.document).ajaxComplete (event, response, options) =>
      switch response.status
        when 401 then @redirectToLogin.apply(@, arguments)
        when 400 then @showError.apply(@, arguments)
        when 403 then @showError.apply(@, arguments)
        when 500 then @showError.apply(@, arguments)

  showError: (event, response, options) ->
    errorView = new Covidence.SystemErrorView(model: {event: event, response: response, options: options})
    $('body #ajax-error-view').remove()
    $('body').prepend(errorView.render().el)

  redirectToLogin: (model, response, options) ->
    window.location.href = '/sign_in'

Covidence.Utils =
  pluralize: (number, text) ->
    "#{number} #{pluralize(text, number)}"
