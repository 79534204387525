class Covidence.PlanInclusionsView extends Backbone.View

  className: 'inclusions row'

  initialize: ->
    @template = HandlebarsTemplates['plans/inclusions']

  render: ->
    @$el.html(@template())
    midPoint = Math.ceil(@collection.length / 2)
    _(@collection).each (inclusion, index) =>
      list = if index < midPoint then "list-1" else "list-2"
      @$el.find(".#{list}").append(new Covidence.InclusionView({model: inclusion}).render().el)
    @

