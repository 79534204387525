class Covidence.FeedbackView extends Backbone.View
  id: "feedback-and-support"
  className: "modal"

  events:
    "click a.cancel" : "closeModal"
    "click a.done" : "closeModal"
    "click a.submit": "saveFeedbackForm"
    "click a.show-form": "loadFeedbackForm"
    "hidden.bs.modal" : "render"

  template: """
    <div class='modal-dialog'>
      <div class='modal-content'>
        <div class='modal-header'>
          <h3>Feedback & Support</h3>
        </div>
        <div class='modal-body'>
          <h4>Find your answer, fast.</h4>
          <p>We've got a new Knowledge base that answers many frequently asked questions. Why not try that first?</p>
          <a href="{{knowledgeBaseUrl}}" class="button primary highlight" target='_blank'>View the knowledge base</a>
          <span>OR</span>
          <a href="javascript:void(0)" class="inline show-form">Send us an email</a>
        </div>
        <div class='modal-footer'>
          <a class='cancel pull-left action-link'>Cancel</a>
          <a href="#" class="button primary right action submit">Submit</a>
          <a class='done button pull-right primary action' style='display: none;'>Close</a>
        </div>
      </div>
    </div>
"""

  initialize: (options) ->
    @attributes = _.pick((options || {}), 'showUrl', 'postUrl', 'knowledgeBaseUrl')

  render: ->
    @$el.empty()
    @$el.append($.mustache(@template, {knowledgeBaseUrl: @attributes.knowledgeBaseUrl}))
    $('body').append(@el)
    @$(".modal-footer").hide()
    @

  loadFeedbackForm: (e) ->
    $.get @attributes.showUrl, (data) =>
      @$(".modal-footer").show()
      @$(".modal-body").html data

  saveFeedbackForm: (e) ->
    e.preventDefault()
    $.post @attributes.postUrl, @$("form").serialize(), (data) =>
      @$('a.submit').hide()
      @$(".modal-body").html data

  closeModal: ->
    @$el.modal "hide"
    $(".nav-item.support a").blur()
