class Covidence.SystemErrorView extends Backbone.View
  id: 'ajax-error-view'
  template403: """
    <div class='container'>
      <span class='message'>
        Oops, you tried to do something you weren't really allowed to do.
      </span>
      <a href='javascript:void(0)' class='dismiss ss-delete'></a>
    </div>
  """
  template400: """
    <div class='container'>
      <span class='message'>
        Oops, something went wrong. Please <a href='javascript:window.location.reload()'>refresh</a>
        your page and <a href='javascript:void(0)' data-target='#feedback-and-support' data-toggle='modal'>contact support</a>
        if the problem persists.
      </span>
      <a href='javascript:void(0)' class='dismiss ss-delete'></a>
    </div>
  """

  events:
    "click a" : "remove"

  render: ->
    switch @model.response.status
      when 400 then @show400()
      when 403 then @show403()
      when 500 then @show400()
      else #nothing
    @

  show400: ->
    @$el.html(@template400)

  show403: ->
    @$el.html(@template403)

