/* eslint-disable */

require('./covidence/application');
require('./views/user_notification_view');
require('./covidence/models/model');
require('./covidence/models/collection');
require('./covidence/models/countries');
require('./covidence/models/payment_service');
require('./covidence/models/payment');
require('./covidence/models/plans');
require('./covidence/models/promotions');
require('./covidence/models/account');
require('./covidence/models/credit_card');
require('./covidence/models/reviewers');
require('./covidence/models/review_statistics');
require('./covidence/models/note');
require('./covidence/models/audit');
require('./covidence/models/util');
require('./covidence/models/user_preferences');
require('./covidence/models/document');
require('./covidence/views/dismissable_message_view');
require('./covidence/views/browser-notification-controller');
require('./covidence/views/s3-direct-upload-view');
require('./covidence/views/feedback-view');
require('./covidence/views/system_error_view');
require('./covidence/views/plan_payment_view');
require('./covidence/views/plan_payment_status_view');
require('./covidence/views/plans_view');
require('./covidence/views/promotion_view');
require('./covidence/views/plan_inclusions_view');
require('./covidence/views/plan_payment_success_view');
require('./covidence/views/plan_payment_failure_view');
require('./covidence/views/wizard');
require('./covidence/views/plan_purchase_wizard');
require('./covidence/views/plans_purchase_router');


$.fn.attachPendingRequestListener = function (eventEl, message) {
  var element = $(this);
  eventEl
    .on('ajax:beforeSend', function (xhr, data) {
      // Handle bubbling up of events from objects
      if (
        typeof data == 'object' &&
        typeof eventEl == 'object' &&
        data.id &&
        eventEl.id != data.id
      ) {
        return false;
      }
      element.css('position', 'relative');
      element.prepend(
        "<div class='pending-request'>" + (message || '') + '</div>'
      );
    })
    .on('ajax:complete', function () {
      element.find('.pending-request').remove();
    });
};

$(document).ready(function () {
  $(document).ajaxSend(function (e, xhr, options) {
    var token = $("meta[name='csrf-token']").attr('content');
    xhr.setRequestHeader('X-CSRF-Token', token);
  });

  $('form.dirty-check').areYouSure();
  $('form.dirty-check').on('dirty.areYouSure', function () {
    $('.flash_message').text('You have unsaved changes').addClass('unsaved');
  });
});
